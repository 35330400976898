import React from 'react';
import SalesCalculator from './SalesCalculator';

function App() {
  return (
    <div>
      <SalesCalculator />
    </div>
  );
}

export default App;