import React, { useState, useEffect } from 'react';
import './SalesCalculator.css';

function SalesCalculator() {
  useEffect(() => {
    document.body.style.overscrollBehaviorY = "contain";

    return () => {
      document.body.style.overscrollBehaviorY = "";
    };
  }, []);

  const [sales, setSales] = useState();
  const [lastYearSales, setLastYearSales] = useState();
  const [conversion, setConversion] = useState();
  const [lastYearConversion, setLastYearConversion] = useState();
  const [goal, setGoal] = useState();
  const [onesight, setOnesight] = useState();
  const [careKits, setCareKits] = useState();
  const [sunUnits, setSunUnits] = useState();
  const [omniSales, setOmniSales] = useState();
  const [sunLove, setSunLove] = useState();
  const [customers, setCustomers] = useState();
  const [asCLY, setAsCLY] = useState();
  const [weather, setWeather] = useState("");
  const [returns, setReturns] = useState();
  const [dataArray, setDataArray] = useState([]);

  
  const [newSales, setNewSales] = useState(null);
  const [newOnesight, setNewOnesight] = useState(null);
  const [newCareKits, setNewCareKits] = useState(null);
  const [newOmniSales, setNewOmniSales] = useState(null);
  const [newSunLove, setNewSunLove] = useState(null);
  const [newSunUnits, setNewSunUnits] = useState(null);

  const [showAddReceipt, setShowAddReceipt] = useState(false);

  //coping the div from buton
  const handleCopy = () => {
    const div = document.getElementById("recapDiv");
    let text = '';
    for (const node of div.childNodes) {
      if (node.nodeType === Node.TEXT_NODE) {
        text += node.nodeValue;
      } else if (node.tagName === 'SPAN') {
        text += node.innerText + '\n';
      } else if (node.tagName === 'BR') {
        text += '\n';
      }
    }

    navigator.clipboard.writeText(text)
      .then(() => {
        console.log('Text successfully copied');
      })
      .catch(err => {
        console.error('Failed to copy text: ', err);
      });
  };

  //collecting the data set on the const for buton
  const handleCollectData = () => {
    const data = [
      goal, sales, lastYearSales, conversion, lastYearConversion,
      onesight, careKits, sunUnits, omniSales, sunLove,
      customers, asCLY, returns, weather
    ];
    
    // Copy to clipboard
    navigator.clipboard.writeText(data.join(','))
      .then(() => {
        console.log('Data successfully copied');
      })
      .catch(err => {
        console.error('Failed to copy data: ', err);
      });
  };

    //assigning the copied text into the imput fields 
  const handlePopulateFields = () => {
  // Read from clipboard
  navigator.clipboard.readText()
    .then(text => {
      const dataArray = text.split(',');
      if (dataArray.length === 14) {
        setGoal(dataArray[0]);
        setSales(dataArray[1]);
        setLastYearSales(dataArray[2]);
        setConversion(dataArray[3]);
        setLastYearConversion(dataArray[4]);
        setOnesight(dataArray[5]);
        setCareKits(dataArray[6]);
        setSunUnits(dataArray[7]);
        setOmniSales(dataArray[8]);
        setSunLove(dataArray[9]);
        setCustomers(dataArray[10]);
        setAsCLY(dataArray[11]);
        setReturns(dataArray[12]);
        setWeather(dataArray[13]);
      }
    })
    .catch(err => {
      console.error('Failed to read clipboard: ', err);
    });
};

const handleAddReceipt = () => {
  if (newSales !== null && newSales !== '') {
    setSales((prevSales) => Number(prevSales || 0) + Number(newSales));
  }
  
  if (newOnesight !== null && newOnesight !== '') {
    setOnesight((prevOnesight) => Number(prevOnesight || 0) + Number(newOnesight));
  }

  if (newCareKits !== null && newCareKits !== '') {
    setCareKits((prevCareKits) => Number(prevCareKits || 0) + Number(newCareKits));
  }
  
  if (newOmniSales !== null && newOmniSales !== '') {
    setOmniSales((prevOmniSales) => Number(prevOmniSales || 0) + Number(newOmniSales));
  }

  if (newSunLove !== null && newSunLove !== '') {
    setSunLove((prevSunLove) => Number(prevSunLove || 0) + Number(newSunLove));
  }
  
  if (newSunUnits !== null && newSunUnits !== '') {
    setSunUnits((prevSunUnits) => Number(prevSunUnits || 0) + Number(newSunUnits));
  }

  // Increment customers count only if at least one field has some value
  if ([newSales, newOnesight, newCareKits, newOmniSales, newSunLove, newSunUnits].some(val => val !== null && val !== '')) {
    setCustomers((prevCustomers) => Number(prevCustomers || 0) + 1);
  }

  // Reset new receipt values to null
  setNewSales(null);
  setNewOnesight(null);
  setNewCareKits(null);
  setNewOmniSales(null);
  setNewSunLove(null);
  setNewSunUnits(null);

  // Hide the Add Receipt inputs
  setShowAddReceipt(false);
};



  const formatNumber = (num) => {
    if (Number.isInteger(num)) {
      return num;
    }
    return num.toFixed(2);
  };




  return (
    <div className="container" >
      <h1>Closing Recap Calculator</h1>
      <div className="input-group">
        <label htmlFor="goal">Goal:</label>
        <input
          type="number"
          id="goal"
          value={goal}
          onChange={(e) => setGoal(e.target.value)}
        />
      </div>
      <div className="input-group">
        <label htmlFor="sales">Sales:</label>
        <input
          type="number"
          id="sales"
          value={sales}
          onChange={(e) => setSales(e.target.value)}
        />
      </div>
      <div className="input-group">
        <label htmlFor="lastYearSales">LY Sales:</label>
        <input
          type="number"
          id="lastYearSales"
          value={lastYearSales}
          onChange={(e) => setLastYearSales(e.target.value)}
        />
      </div>
      <div className="input-group">
        <label htmlFor="conversion">Conversion:</label>
        <input
          type="number"
          id="conversion"
          value={conversion}
          onChange={(e) => setConversion(e.target.value)}
        />
      </div>
      <div className="input-group">
        <label htmlFor="lastYearConversion">LY Conversion:</label>
        <input
          type="number"
          id="lastYearConversion"
          value={lastYearConversion}
          onChange={(e) => setLastYearConversion(e.target.value)}
        />
      </div>
     
      <div className="input-group">
        <label htmlFor="onesight">One Sight:</label>
        <input
          type="number"
          id="onesight"
          value={onesight}
          onChange={(e) => setOnesight(e.target.value)}
        />
      </div>
      <div className="input-group">
        <label htmlFor="careKits">Care Kits:</label>
        <input
          type="number"
          id="careKits"
          value={careKits}
          onChange={(e) => setCareKits(e.target.value)}
        />
      </div>
      <div className="input-group">
        <label htmlFor="sunUnits">Sun Units:</label>
        <input
          type="number"
          id="sunUnits"
          value={sunUnits}
          onChange={(e) => setSunUnits(e.target.value)}
        />
      </div>
      <div className="input-group">
        <label htmlFor="omniSales">Omni Sales:</label>
        <input
          type="number"
          id="omniSales"
          value={omniSales}
          onChange={(e) => setOmniSales(e.target.value)}
        />
      </div>
      
    
      

      <div className="input-group">
      <label htmlFor="sunLove">Sun Love:</label>
        <input type="number" 
        value={sunLove} 
        onChange={(e) => setSunLove(e.target.value)} />
      </div>
      
      <div className="input-group">
      <label htmlFor="customers">Customers:</label>
        <input type="number" value={customers} onChange={(e) => setCustomers(e.target.value)} />
      
      </div>
      
      <div className="input-group">
      <label htmlFor="asCLY">A$CLY:</label>
        
        <input type="number" value={asCLY} onChange={(e) => setAsCLY(e.target.value)} />
      
        </div>
          {//hii 
      }

<div className="input-group">
  <label htmlFor="returns">Returns:</label>
  <input
    type="number"
    id="returns"
    value={returns}
    onChange={(e) => setReturns(e.target.value)}
  />
</div>



      <div className="input-group">
      <label htmlFor="weather">Weather:</label>
      <input
        type="text"
        id="weather"
        value={weather}
        onChange={(e) => setWeather(e.target.value)}
      />
    </div>


    {//hii
    }
      

      {/* 
  ----getting button----


      
      <button onClick={handleCalculate}>Calculate</button>
      
      */}

      <br />
      <br />



      

      <button onClick={handleCopy} style={{ fontSize: '30px', padding: '10px 20px' }}>
        Copy Recap
      </button>
      <br />
      <br />
      <br />

       {/*button receipt*/}
       <button onClick={() => setShowAddReceipt(!showAddReceipt)}>Add Receipt</button>

{showAddReceipt && (
  <div className="receipt-box">
    <div>
      <label>Sales (net):</label>
      <input type="number" value={newSales || ''} onChange={(e) => setNewSales(e.target.value)} />
    </div>
    <div>
      <label>Onesight:</label>
      <input type="number" value={newOnesight || ''} onChange={(e) => setNewOnesight(e.target.value)} />
    </div>
    <div>
      <label>CareKits:</label>
      <input type="number" value={newCareKits || ''} onChange={(e) => setNewCareKits(e.target.value)} />
    </div>
    <div>
      <label>Omni Sales:</label>
      <input type="number" value={newOmniSales || ''} onChange={(e) => setNewOmniSales(e.target.value)} />
    </div>
    <div>
      <label>Sun Love:</label>
      <input type="number" value={newSunLove || ''} onChange={(e) => setNewSunLove(e.target.value)} />
    </div>
    <div>
      <label>Sun Units:</label>
      <input type="number" value={newSunUnits || ''} onChange={(e) => setNewSunUnits(e.target.value)} />
    </div>
    <div>
      <button onClick={handleAddReceipt}>Add this Receipt</button>
    </div>
  </div>
)}









      
      <div id="recapDiv">

        
        <span>Closing Recap</span>
        <br />
        
        <span>Sales: ${sales}</span>
        <span>Goal: ${goal} </span> 
        <span>% to Goal: {formatNumber(sales / goal * 100)}% </span> 
        <span>% to COMP: {formatNumber(sales / lastYearSales * 100)}% </span>
        <br />
        <span>Conversion: {conversion}%</span>
        <span>LY Conversion: {lastYearConversion}%</span>
        <br />
        <span>One Sight: ${onesight}</span>
        <span>% to goal: {formatNumber(onesight / sales * 100)}%</span>
        <br />
        <span>Carekits: {careKits}</span>
        <span>Sun units: {sunUnits}</span>
        <span>% to goal: {formatNumber(careKits / sunUnits * 100)}%</span>
        <br />
        <span>Omni sales: ${omniSales}</span>
        <span>% to goal: {formatNumber(omniSales / sales * 100)}%</span>
        <br />
        <span>Sun Love: {sunLove}</span>
        <span>% to goal: {formatNumber(sunLove / sunUnits * 100)}%</span>
        <br />
        <span>Customers: {customers}</span>
        <span>Customer Goal: {formatNumber(goal / asCLY)}</span>
        <br />
        <span>A$C: {formatNumber(sales / customers)}</span>
        <span>A$C LY: ${asCLY}</span>
        <span>% to A$C: {formatNumber((sales / customers) / asCLY * 100)}%</span>
        <br />
        <span>Returns: ${returns}</span>
        <span>Weather: {weather}</span><br />
        <span>Thank you.</span>

      </div>


     

        <br />
        <br />
        <br />
        <br />
      
      <button onClick={handleCollectData}>Collect Data</button>
      <button onClick={handlePopulateFields}>Populate Fields</button>

    </div>
  );


  
}



export default SalesCalculator;